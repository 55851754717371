@import '../../regional/scss/super-sections/default-variables';

@import '../../regional/scss/super-sections/header';
@import '../../regional/scss/super-sections/subsectionandarticle-header';
@import '../../regional/scss/super-sections/super-section-page';

/* variable overrides for BT sections */
.Ballspark {
  --super-text-color: #{#fff};
  --super-bg-color: #{#f62924};
  --title-bg-color: #{#941916};
  --link-bg-color: #{#941916};
  --link-bg-hover: #{#62100e};
  --link-bg-active: #{#4a0c0b};
}

// Ballspark CSS overrides
.super-header.Ballspark {
  .super-header-content-wrapper .super-title-container {
    .super-logo {
      background: none;
      backdrop-filter: unset;
    }
  }
  .super-links-container {
    .super-link {
      flex: 1;
    }
    .link-0 {
      flex: 0 0 $component-size * 1.28;
      min-height: $component-size * 1.28;
    }
    .link-1 {
      flex: 1 1 calc(100% - ($component-size * 1.4));
      min-height: $component-size * 1.28;
      @media (min-width: $tablet-width) {
        flex-basis: calc(100% - $component-size * 3.3);
      }
    }
    .link-2 {
      flex: 0 0 $component-size * 1.91;
    }
    .link-3 {
      flex: 1 1 calc(100% - ($component-size * 2));
    }

    @media (min-width: $tablet-breakpoint) {
      .link-0,
      .link-1 {
        height: $component-size * 1.92;
      }
      .link-2,
      .link-3 {
        height: $component-size * 0.95;
      }
      .link-0 {
        flex-basis: $component-size * 1.92;
      }
      .link-2 {
        flex-basis: $component-size * 2.73;
      }
      .link-3 {
        flex: 1 1 calc(100% - ($component-size * 2.9));
      }
    }
  }
}

//Hus og hjem CSS variables overrides
.Husoghjem {
  --super-text-color: var(--color-green-shade-90);
  --super-bg-color: var(--color-green-tint-90);
  --title-bg-color: var(--color-green-tint-70);
  --link-text-hover: #{#fff};
  --link-text-active: #{#fff};
  --link-bg-color: var(--color-green-tint-60);
  --link-bg-hover: var(--color-green-shade-80);
  --link-bg-active: var(--color-green-shade-80);
  --cover-gradient-color: rgba(0, 80, 44, 0.25);
  --cover-gradient-color-hover: rgba(0, 80, 44, 0.15);
  --logo-height-br-sm: 1.625rem;
  --logo-height-br-md: 1.625rem;
  --logo-height-br-lg: 2.5rem;
  --logo-height-br-xl: 2.5rem;
  --h-gap-sm: 0.25rem;
  --h-gap-lg: 0.5rem;
}

// Hus og hjem CSS rules overrides
.super-header.Husoghjem {
  .super-header-content-wrapper {
    gap: var(--h-gap-sm);
    .super-title-container .super-logo {
      width: fit-content;
      background-color: #fff;
      border-radius: 1rem 1rem 1rem 0;
      padding: var(--spacing-spacing-16) var(--spacing-spacing-24);
      img {
        @media (min-width: $tablet-width) {
          filter: brightness(0) invert(1);
        }
      }
      @media (min-width: $tablet-width) {
        border-radius: 1rem;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
    @media (min-width: $desktop-width) {
      gap: var(--h-gap-lg);
    }
  }
  .super-header-content-wrapper .super-links-container {
    gap: var(--h-gap-sm);
    font-family: $fontSerif;

    .super-link {
      min-height: 4.5rem;
      font-size: 1.25rem;
      font-weight: 500;
      letter-spacing: -0.03375rem;
      &:hover {
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
    .link-0 {
      flex-basis: calc(64% - var(--h-gap-sm));
      min-height: 8rem;
    }
    .link-1 {
      flex-basis: calc(36% - var(--h-gap-sm));
      min-height: 8rem;
    }
    // check with UX for the tablet sizes
    @media (min-width: $tablet-width) {
      .super-link {
        .link-title {
          margin-bottom: -0.2rem;
        }
      }
    }
    @media (min-width: $desktop-width) {
      .super-link {
        font-size: 1.6875rem;
      }
      .link-0 {
        flex-basis: calc(64% - var(--h-gap-lg));
        min-height: 12rem;
      }
      .link-1 {
        flex-basis: calc(36% - var(--h-gap-lg));
        min-height: 12rem;
      }
      .link-2,
      .link-3 {
        min-height: 5.9375rem;
      }
    }
    @media (min-width: $desktop-width) {
      gap: var(--h-gap-lg);
    }
  }
}

// submenu styles
.super-header-subsection.Husoghjem {
  margin-top: -14px;
  margin-bottom: 14px;
  .super-header-content-wrapper {
    gap: $gapSmall;
    flex-direction: column;
    .super-title-container {
      min-height: 3.4375rem;
      height: fit-content;
      width: fit-content;
      background-color: #fff;
      border-radius: 1rem 1rem 1rem 0;
      padding: var(--spacing-spacing-16);
      .super-logo {
        height: 1.4375rem;
      }
      &:hover {
        background-color: var(--color-green-shade-80);
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
    .super-links-container {
      .super-link {
        flex: 1 1 calc(50% - $gapSmall);
        padding: var(--spacing-spacing-16);
        flex-direction: row;
        align-items: center;
        justify-content: start;
        min-height: 3.4375rem;
        border-radius: 1rem 1rem 1rem 0;
        font-family: $fontSerif;
        font-size: 1.0625rem;
        line-height: 1.05em;
        font-weight: 500;
        letter-spacing: -0.03375rem;

        img {
          height: 1.125rem;
        }
        .link-title {
          align-self: flex-end;
        }
        &:hover {
          color: var(--link-text-hover);
          background-color: var(--color-green-shade-80);
        }
        &:hover img,
        &.selected img {
          filter: brightness(0) invert(1);
        }
      }
      .link-0 {
        flex-basis: calc(70% - $gapSmall);
      }
      .link-1 {
        flex-basis: calc(30% - $gapSmall);
      }
    }
    @media (min-width: $desktop-width) {
      .super-links-container {
        .super-link {
          flex: 1 1 15%;
        }
      }
      flex-direction: row;
    }
  }
}
