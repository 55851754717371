.widget-real-estate.regional-widget {
    .super-header.Husoghjem {
        --logo-height-br-sm: 2.5rem;
        margin-top: 12px;
        margin-bottom: 12px;

        .super-header-content-wrapper {
            padding: 0;
            gap: 0;

            .super-title-container {
                position: relative;
                display: flex;
                min-width: 100%;
                min-height: 14rem;
                overflow: hidden;
                align-items: center;
                justify-content: center;
                .super-logo {
                    z-index: 2;
                    border-radius: 0.75rem;
                    backdrop-filter: blur(0.3rem);
                    background: rgba(255, 255, 255, 0.2);
                    padding: 1.125rem 1.5rem;
                    img {
                        transition: all 0.5s ease;
                        filter: brightness(0) invert(1);
                    }
                }
                &:hover .super-logo img {
                    height: calc(var(--logo-height-br-sm) + 0.1rem);
                }
                .title-cover {
                    display: block;
                    position: absolute;
                    height: 100%;
                    min-width: 100%;
                    background: linear-gradient(
                            0deg,
                            var(--cover-gradient-color, rgba(0, 0, 0, 0)) 0%,
                            var(--cover-gradient-color, rgba(0, 0, 0, 0)) 100%
                        ),
                        var(--cover-url);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                }
                &:hover .title-cover {
                    background-image: linear-gradient(
                            0deg,
                            var(--cover-gradient-color-hover, rgba(0, 0, 0, 0))
                                0%,
                            var(--cover-gradient-color-hover, rgba(0, 0, 0, 0))
                                100%
                        ),
                        var(--cover-url);
                }
            }
            .super-links-container {
                padding: 0.75rem;

                .super-link {
                    font-size: 0.9375rem;
                    min-height: 3.375rem;
                }
            }
        }
        @media (min-width: 600px) {
            padding: 0.75rem;

            .super-header-content-wrapper {
                gap: 0.75rem;
                .super-title-container {
                    min-height: unset;
                    min-width: unset;
                    .title-cover {
                        min-width: unset;
                    }
                    &:hover .super-logo img {
                        height: calc(var(--logo-height-br-md) + 0.1rem);
                    }
                }
                .super-links-container {
                    padding: 0;

                    .super-link {
                        font-size: 1.2rem;
                        min-height: 4.5rem;
                    }
                    .link-0,
                    .link-1 {
                        min-height: 8rem;
                    }
                }
            }
        }
        @media (min-width: 900px) {
            .super-header-content-wrapper {
                .super-title-container {
                    &:hover .super-logo img {
                        height: calc(var(--logo-height-br-lg) + 0.1rem);
                    }
                }
                .super-links-container {
                    .super-link {
                        font-size: 1.6875rem;
                        min-height: 6rem;
                    }
                    .link-0,
                    .link-1 {
                        min-height: 12rem;
                    }
                }
            }
        }
    }
}
