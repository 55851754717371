.ch-sub-links {
  padding-top: 30px;
}

.super-header-article,
.super-header-subsection {
  display: flex;
  justify-content: center;
  background-color: $super-header-bg;
  overflow: hidden;

  .super-header-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: unset;
    font-family: $fontSansSerif;
    font-weight: normal;
    color: $super-text-color;
    padding: $gapStandard * 2 $gapStandard $gapStandard;
    box-sizing: border-box;
    > * {
      max-width: 980px;
    }
  }

  .super-title-container {
    box-sizing: border-box;
    margin: 0;
    .super-logo {
      height: 3.5rem;
      img {
        height: 100%;
      }
    }
    .title-cover {
      display: none;
    }
  }

  .super-links-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $gapSmall;
    max-width: 100%;
    box-sizing: border-box;
    font-size: 0.94rem;

    .super-link {
      position: relative;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: flex-end;
      font-weight: 700;
      line-height: 100%;
      white-space: nowrap;
      background-color: $link-bg-color;
      border-radius: 16px 16px 16px 0;
      overflow: hidden;
      min-width: fit-content;
      min-height: $component-size * 0.54;
      padding: $gapSmall;
      gap: $gapSmall;

      .link-icon {
        height: 1.2rem;
        width: fit-content;
        opacity: 66%;
        z-index: 2;
      }
      .link-title {
        z-index: 2;
      }
      .link-cover-img {
        display: none;
      }
      .link-bg-art {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
      }
      .link-bg-icon {
        mix-blend-mode: soft-light;
        transform: rotate(12deg);
        opacity: 60%;
        width: $component-size;
        height: $component-size;
        right: 15%;
        top: -10%;
      }
    }
    .link-0 {
      flex: 0 0 $component-size * 1.28;
    }
    .link-1 {
      flex: 1 1 calc(100% - ($component-size * 1.4));
    }
    .link-2 {
      flex: 0 0 $component-size * 1.91;
    }
    .link-3 {
      flex: 1 0 auto;
    }
    .selected {
      color: $link-text-active;
      background-color: $link-bg-active;
    }
  }

  @media (min-width: $tablet-width) {
    .super-header-content-wrapper {
      flex-direction: row;
      gap: $gapSmall;
      padding: 16px $gapStandard;
    }

    .super-title-container {
      position: relative;
      display: flex;
      width: $component-size * 1.26;
      min-width: fit-content;
      padding: $gapSmall;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      height: 72px;

      .super-logo {
        height: 100%;
        z-index: 2;
      }
      .super-title {
        z-index: 2;
      }
    }

    .super-links-container {
      flex: 1;
      font-size: 0.86rem;

      .super-link {
        flex: 1;
        width: auto;
        min-height: fit-content;
        max-height: 72px;

        &:hover {
          color: $link-text-hover;
          background-color: $link-bg-hover;
          .link-cover-img {
            opacity: 100%;
          }
        }
        &:active {
          background-size: 106%;
          background-color: $link-bg-active;
          .link-cover-img {
            background-size: 106%;
          }
          .link-bg-art {
            display: none;
          }
        }

        .link-cover-img {
          display: block;
          bottom: 0;
          left: 0;
          top: 0;
          right: 0;
          position: absolute;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          opacity: 60%;
        }
      }
    }
  }
  @media (min-width: $tablet-breakpoint) {
    .super-header-content-wrapper {
      .super-title-container {
        flex: 1;
      }
      .super-links-container {
        flex: 6;
        font-size: 0.94rem;

        .super-link {
          .link-bg-icon {
            width: $component-size * 1.3;
            height: $component-size * 1.3;
          }
        }
      }
    }
  }
  @media (min-width: $desktop-width) {
    .super-header-content-wrapper {
      max-width: $desktop-width;
      padding: 16px 0;

      .super-links-container {
        font-size: 1rem;
      }
    }
  }
}

// Article subsection overrides
.super-header-article {
  .super-header-content-wrapper {
    flex-direction: row;
    align-items: center;
    gap: $gapSmall;
    overflow-x: auto;
  }

  .super-links-container {
    flex-wrap: nowrap;
    // Hack to give padding to the overflowed content
    margin-right: $component-size;

    .super-link {
      flex-direction: row;
      justify-content: start;
      align-items: center;
      flex: unset;
      min-height: unset;
      padding: 10px 10px 12px 10px;

      .link-bg-art {
        display: none;
      }
    }
  }
}

@media (min-width: $tablet-width) {
  .super-header-article {
    .super-links-container {
      .super-link {
        flex-direction: column;
        justify-content: flex-end;
        align-items: start;
        min-height: $component-size * 0.54;
        flex: 0 0 180px;

        .link-bg-art {
          display: block;
        }
      }
    }
  }
}

@media (min-width: $desktop-width) {
  .super-header-article {
    .super-links-container {
      flex-wrap: wrap;

      // Undo article overflow hack
      margin-right: 0;
    }
  }
}
