.ch-sub-links {
  padding-top: 30px;
}

.super-header {
  display: flex;
  justify-content: center;
  background: $super-header-bg;
  overflow: hidden;

  .super-header-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: unset;
    font-family: $fontSansSerif;
    font-weight: normal;
    color: $super-text-color;
    padding: $gapStandard;
    box-sizing: border-box;
    > * {
      max-width: 980px;
    }
  }

  .super-title-container {
    box-sizing: border-box;
    margin: 0;
    .super-logo {
      img {
        height: var(--logo-height-br-sm, 5rem);
      }
    }
    .title-cover {
      display: none;
    }
  }

  .super-links-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $gapSmall;
    max-width: 100%;
    box-sizing: border-box;
    font-size: 0.94rem;

    .super-link {
      position: relative;
      display: flex;
      flex: 1 1 calc(50% - $gapSmall);
      flex-direction: column;
      box-sizing: border-box;
      justify-content: flex-end;
      font-weight: 700;
      line-height: 100%;
      white-space: nowrap;
      color: $link-text-color;
      background-color: $link-bg-color;
      border-radius: 16px 16px 16px 0;
      overflow: hidden;
      min-width: fit-content;
      min-height: $component-size * 0.75;
      padding: $gapSmall;
      gap: $gapSmall;

      .link-icon {
        height: 1.2rem;
        width: fit-content;
        opacity: 66%;
        z-index: 2;
      }
      .link-title {
        z-index: 2;
      }
      .link-cover-img {
        height: 100%;
        width: 100%;
        margin: -$gapSmall;
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 60%;
      }
      .link-bg-art {
        position: absolute;
        margin: -$gapSmall;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
      }
      .link-bg-icon {
        mix-blend-mode: soft-light;
        transform: rotate(12deg);
        opacity: 60%;
        width: $component-size;
        height: $component-size;
        right: 15%;
        top: -10%;
      }
    }
  }

  @media (min-width: $tablet-width) {
    margin-top: -14px;
    margin-bottom: $gapStandard;

    .super-header-content-wrapper {
      flex-direction: row;
      gap: $gapSmall;
      padding: $gapStandard * 2 $gapStandard $gapStandard;
    }

    .super-title-container {
      position: relative;
      display: flex;
      width: $component-size * 2.09;
      padding: $gapSmall $gapStandard;
      border-radius: 16px;
      overflow: hidden;
      background-color: $super-title-bg;
      align-items: center;
      justify-content: center;

      .super-logo {
        z-index: 2;
        border-radius: 0.7895rem;
        backdrop-filter: blur(0.3rem);
        background: rgba(255, 255, 255, 0.2);
        padding: 1.13rem 1.5rem;
        img {
          transition: all 0.5s ease;
          height: var(--logo-height-br-md, 4.5rem);
        }
      }
      &:hover .super-logo img {
        height: calc(var(--logo-height-br-md, 4.5rem) + 0.1rem);
      }
      .super-title {
        z-index: 2;
      }
      .title-cover {
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        background: linear-gradient(
            0deg,
            var(--cover-gradient-color, rgba(0, 0, 0, 0)) 0%,
            var(--cover-gradient-color, rgba(0, 0, 0, 0)) 100%
          ),
          var(--cover-url);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
      &:hover .title-cover {
        background-image: linear-gradient(
            0deg,
            var(--cover-gradient-color-hover, rgba(0, 0, 0, 0)) 0%,
            var(--cover-gradient-color-hover, rgba(0, 0, 0, 0)) 100%
          ),
          var(--cover-url);
      }
    }

    .super-links-container {
      flex: 1;

      .super-link {
        padding: $gapStandard;

        &:hover {
          color: $link-text-hover;
          background-color: $link-bg-hover;
          .link-cover-img {
            opacity: 100%;
          }
        }
        &:active {
          background-size: 106%;
          background-color: $link-bg-active;
          .link-cover-img {
            background-size: 106%;
          }
          .link-bg-art {
            display: none;
          }
        }
        .link-cover-img {
          margin: -$gapStandard;
        }
        .link-bg-art {
          margin: -$gapStandard;
        }
      }
      .link-0 {
        flex-basis: calc(40% - $gapSmall);
      }
      .link-1 {
        flex-basis: calc(60% - $gapSmall);
      }
    }
  }
  @media (min-width: $tablet-breakpoint) {
    .super-header-content-wrapper {
      .super-title-container {
        flex: 1;
        .super-logo {
          img {
            transition: all 0.5s ease;
            height: var(--logo-height-br-lg, 6.5rem);
          }
        }
        &:hover .super-logo img {
          height: calc(var(--logo-height-br-lg, 6.5rem) + 0.1rem);
        }
      }
      .super-links-container {
        flex: 0 0 $component-size * 5.49;
        font-size: 1.5rem;

        .super-link {
          .link-bg-icon {
            width: $component-size * 1.3;
            height: $component-size * 1.3;
          }
        }
      }
    }
  }
  @media (min-width: $desktop-width) {
    .super-header-content-wrapper {
      max-width: $desktop-width;
      padding: $gapStandard * 2 0 $gapStandard * 2;

      .super-title-container {
        .super-logo {
          img {
            height: var(--logo-height-br-xl, 8rem);
          }
        }
        &:hover .super-logo img {
          height: calc(var(--logo-height-br-xl, 8rem) + 0.1rem);
        }
      }
    }
  }
}
